import _ from "lodash";

// Konstans a mozdíthatatlan csempéhez
export const IMMOVABLE_TILE_ID = -2;

export const generateImmovableTiles = (
  board: number[][],
  count: number
): number[][] => {
  const newBoard = _.cloneDeep(board);
  const boardSize = newBoard.length * newBoard[0].length;
  const tilesToPlace = Math.min(count, boardSize);
  let tilesPlaced = 0;

  while (tilesPlaced < tilesToPlace) {
    const row = Math.floor(Math.random() * newBoard.length);
    const col = Math.floor(Math.random() * newBoard[0].length);

    // Csak akkor helyezünk le mozdíthatatlan csempét, ha az adott helyen még nincs ilyen vagy törhetetlen
    if (newBoard[row][col] !== IMMOVABLE_TILE_ID && newBoard[row][col] !== -1) {
      newBoard[row][col] = IMMOVABLE_TILE_ID;
      tilesPlaced++;
    }
  }
  return newBoard;
};

export const isImmovableTile = (tile: number): boolean => {
  return tile === IMMOVABLE_TILE_ID;
};
