import _ from "lodash";
import { NextIndexes, TilePos } from "./Board.types";
import { UNBREAKABLE_TILE_ID } from "./UnbreakableTile.utils";
import { LINE_DESTROYER_TILE_ID, BOMB_TILE_ID } from "./SpecialTiles.utils";
import { HOLE_TILE_ID } from "./hole.utils"; // Importáld a HOLE_TILE_ID-t

export const matrixArray = (board: number[][]): number[][] =>
    board[0].map((_, colIdx) => board.map(row => row[colIdx]));

export const createBoard = (size: number, numbers: number[]): number[][] => {
    const make2dArray = (size: number) => Array(size)
        .fill(null)
        .map(() =>
            Array(size)
                .fill(0)
                .map(() => _.sample(numbers)!)
        );
    let board = make2dArray(size)
    while (!_.isEqual(board, checkForAllMatches(board))) {
        board = make2dArray(size)
    }
    return board
};

const checkForMatchesHorizontal = (board: number[][], subsTilesToGo?: (id: number, quan: number) => void): number[][] => {
    const tiles = _.cloneDeep(board);
    const boardSize = tiles.length;

    for (let i = 0; i < boardSize; i++) {
        const row = tiles[i];
        let queue: number[] = []; 

        for (let j = 0; j <= boardSize; j++) {
            if (row[j] === UNBREAKABLE_TILE_ID || row[j] === 0 || j === boardSize) {
                if (queue.length >= 3) {
                    const tileType = row[queue[0]];

                    // Ha 4 egyezés van, a sor utolsó eleméből line destroyer lesz
                    if (queue.length === 4) {
                        row[queue[queue.length - 1]] = LINE_DESTROYER_TILE_ID;
                        // Az összes többi csempe törlése
                        for (let k = 0; k < queue.length - 1; k++) {
                            row[queue[k]] = 0;
                        }
                    }
                   // Ha 5 egyezés van, a sor középső eleméből bomba lesz
          else if (queue.length >= 5) {
            const middleIndex = Math.floor(queue.length / 2);
            row[queue[middleIndex]] = BOMB_TILE_ID;
            for (let k = 0; k < queue.length; k++) {
              if (k !== middleIndex) row[queue[k]] = 0;
            }
          }
                    // Ha pontosan 3 egyezés van, mindhárom csempe eltűnik
                    else if (queue.length === 3) {
                        for (let k = 0; k < queue.length; k++) {
                            row[queue[k]] = 0;
                        }
                    }

                    // Célfrissítés
                    if (subsTilesToGo && tileType !== 0 && tileType !== UNBREAKABLE_TILE_ID) {
                        subsTilesToGo(tileType, queue.length);
                    }
                }
                queue = [];
            } else if (queue.length === 0 || row[j] === row[queue[0]]) {
                queue.push(j); 
            } else {
                if (queue.length >= 3) {
                    const tileType = row[queue[0]];

                    if (queue.length === 4) {
                        row[queue[queue.length - 1]] = LINE_DESTROYER_TILE_ID;
                        for (let k = 0; k < queue.length - 1; k++) {
                            row[queue[k]] = 0;
                        }
                    } else if (queue.length >= 5) {
                        row[queue[queue.length - 1]] = BOMB_TILE_ID;
                        for (let k = 0; k < queue.length - 1; k++) {
                            row[queue[k]] = 0;
                        }
                    } else if (queue.length === 3) {
                        for (let k = 0; k < queue.length; k++) {
                            row[queue[k]] = 0;
                        }
                    }

                    if (subsTilesToGo && tileType !== 0 && tileType !== UNBREAKABLE_TILE_ID) {
                        subsTilesToGo(tileType, queue.length);
                    }
                }
                queue = [j];
            }
        }
    }
    return tiles;
};  

export const hasEmptyTiles = (board: number[][]): boolean =>
    board.some(row => row.some(tile => tile === 0));

export const getValuesDown = (board: number[][]): number[][] => {
    const newBoard = _.cloneDeep(board);

    for (let i = 1; i < newBoard.length; i++) {
        for (let j = 0; j < newBoard[i].length; j++) {
            // Ellenőrizzük, hogy az aktuális hely lyuk-e, és nem az első sorban van
            if (newBoard[i][j] === 0 && newBoard[i][j] !== HOLE_TILE_ID) {
                let k = i - 1;
                // Lyukak átugrása
                while (k >= 1 && newBoard[k][j] === HOLE_TILE_ID) {
                    k--;
                }
                if (k >= 0) {
                    newBoard[i][j] = newBoard[k][j];
                    newBoard[k][j] = 0;
                }
            }
        } 
    }
    return newBoard;
};

  
  
  export const addNewRow = (board: number[][], numbers: number[]): number[][] => {
    const newBoard = _.cloneDeep(board);

    newBoard[0] = newBoard[0].map((num, index) => {
        // Ha a csempe értéke lyuk (HOLE_TILE_ID), hagyjuk érintetlenül
        if (num === HOLE_TILE_ID) {
            return HOLE_TILE_ID;
        }
        // Ha a csempe értéke 0, töltjük fel egy új véletlenszerű csempével
        return num === 0 ? _.sample(numbers)! : num;
    });

    return newBoard;
};


export const checkForAllMatches = (board: number[][], subsTilesToGo?: (id: number, quan: number) => void): number[][] => {
    let newBoard = _.cloneDeep(board);
    newBoard = checkForMatchesHorizontal(newBoard, subsTilesToGo);
    newBoard = matrixArray(checkForMatchesHorizontal(matrixArray(newBoard), subsTilesToGo));
    return newBoard;
};

// 

const toggleArray = (
    board: number[][],
    tilePos: TilePos,
    nextTileIndex: -1 | 0 | 1
): number[][] => {
    const newBoard = _.cloneDeep(board);
    const { x, y } = tilePos;

    [newBoard[y][x], newBoard[y][x + nextTileIndex]] = [
        newBoard[y][x + nextTileIndex],
        newBoard[y][x]
    ];

    return newBoard;
};

const reverseCoords = (pos: TilePos): TilePos => ({
    y: pos.x,
    x: pos.y
});


const toggle = {
    hotizontal: (
        board: number[][],
        tilePos: TilePos,
        nextTileIndex: 1 | 0 | -1
    ) => toggleArray(board, tilePos, nextTileIndex),
    vertical: (
        board: number[][],
        tilePos: TilePos,
        nextTileIndex: 1 | 0 | -1
    ) =>
        matrixArray(
            toggleArray(matrixArray(board), reverseCoords(tilePos), nextTileIndex)
        )
};

export const swipeTiles = (
    board: number[][],
    pos: TilePos,
    dir: NextIndexes
): number[][] => {

    const isHorizontal = dir.x !== 0

    const nextTileIndex = isHorizontal ? dir.x : dir.y

    return toggle[isHorizontal ? "hotizontal" : "vertical"](
        board,
        pos,
        nextTileIndex
    );
}

// 

export const calculateDirection = (
    offsetX: number,
    offsetY: number
): NextIndexes | null => {
    const isHorizontal = Math.abs(offsetX) > Math.abs(offsetY);
    const OFFSET_TO_MAKE_MOVE = 20
    if (Math.abs(offsetX) < OFFSET_TO_MAKE_MOVE && Math.abs(offsetY) < OFFSET_TO_MAKE_MOVE) {
        return null
    }

    const x = offsetX > 0 ? 1 : -1;
    const y = offsetY > 0 ? 1 : -1;

    return {
        x: 0 + (isHorizontal ? x : 0),
        y: 0 + (!isHorizontal ? y : 0),
    } as NextIndexes
};

export const calculateNewPosition = (
    tilePos: TilePos,
    direction: TilePos
): TilePos => {
    const x = tilePos.x + direction.x;
    const y = tilePos.y + direction.y;

    return { x, y };
};

export const isValidMove = (currentPos: TilePos, newPos: TilePos, board: number[][]): boolean => {
    const { x: cx, y: cy } = currentPos;
    const { x: nx, y: ny } = newPos;
    const boardSize = board.length;
  
    // Érvényes pozíciók és lyukak elkerülése mind a forrás, mind a cél helyen
    const isInBounds = cx >= 0 && cx < boardSize && cy >= 0 && cy < boardSize && nx >= 0 && nx < boardSize && ny >= 0 && ny < boardSize;
    return isInBounds && board[cy][cx] !== HOLE_TILE_ID && board[ny][nx] !== HOLE_TILE_ID;
  };
  