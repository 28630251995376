import React, { Dispatch, useEffect, useState } from "react";
import _ from "lodash";
import {
  checkForAllMatches,
  hasEmptyTiles,
  addNewRow,
  getValuesDown,
  swipeTiles,
  calculateDirection,
  calculateNewPosition,
  isValidMove,
} from "./Board.utils";
import * as S from "./Board.style";
import { TilePos } from "./Board.types";
import Tile from "../Tile/Tile";
import { BOMB_TILE_ID, LINE_DESTROYER_TILE_ID } from "./SpecialTiles.utils";
import { IMMOVABLE_TILE_ID } from "./ImmovableTile.utils";
import { HOLE_TILE_ID } from "./hole.utils";

const TILE_SPEED_MS = 100;

interface BoardProps {
  move: () => void;
  subsTilesToGo: (id: number, quan: number) => void;
  isPlayable: boolean;
  setIsPlayable: Dispatch<React.SetStateAction<boolean>>;
  board: number[][];
  setBoard: Dispatch<React.SetStateAction<number[][]>>;
  numbers: number[];
}

const Board: React.FC<BoardProps> = ({
  move,
  subsTilesToGo,
  isPlayable,
  setIsPlayable,
  board,
  setBoard,
  numbers,
}) => {
  const [firstDraggedDiv, setFirstDraggedDiv] = useState<TilePos | null>(null);
  const [selectedTile, setSelectedTile] = useState<TilePos | null>(null);

  const handleInteraction = (startPos: TilePos, endPos: TilePos) => {
    const movedTile = board[startPos.y][startPos.x];
    const targetTile = board[endPos.y][endPos.x];
  
    // Ellenőrizzük, hogy a mozgás valódi (nem ugyanazon a helyen maradt)
    const distance = Math.abs(startPos.x - endPos.x) + Math.abs(startPos.y - endPos.y);
    if (distance > 1 || (startPos.x === endPos.x && startPos.y === endPos.y)) {
      setSelectedTile(null);
      return;
    }
  
    if (
      !isValidMove(startPos, endPos, board) ||
      movedTile === IMMOVABLE_TILE_ID ||
      targetTile === IMMOVABLE_TILE_ID
    ) {
      setSelectedTile(null);
      return;
    }
  
    const clampToDirection = (value: number): -1 | 0 | 1 => {
      if (value > 0) return 1;
      if (value < 0) return -1;
      return 0;
    };
    
    const updatedBoard = swipeTiles(board, startPos, {
      x: clampToDirection(endPos.x - startPos.x),
      y: clampToDirection(endPos.y - startPos.y),
    });
  
    if (movedTile === BOMB_TILE_ID && targetTile === BOMB_TILE_ID) {
      const destroyedTiles: TilePos[] = [];
    
      // Készítsük el az új táblát és nyomozzuk a törölt csempéket
      const newBoard = board.map((row, rowIndex) =>
        row.map((tile, colIndex) => {
          if (tile !== IMMOVABLE_TILE_ID && tile !== HOLE_TILE_ID) {
            destroyedTiles.push({ x: colIndex, y: rowIndex });
            return 0; // Eltüntetjük a csempét
          }
          return tile;
        })
      );
    
      // Hívjuk meg a subsTilesToGo függvényt az elpusztított csempékhez
      destroyedTiles.forEach(({ x, y }) => {
        subsTilesToGo(board[y][x], 1);
      });
    
      setBoard(newBoard);
      move();

    } else if (movedTile === LINE_DESTROYER_TILE_ID && targetTile === LINE_DESTROYER_TILE_ID) {
      let newBoard = handleLineDestroyerEffect(endPos, true, _.cloneDeep(board));
      newBoard = handleLineDestroyerEffect(endPos, false, newBoard);
      setBoard(newBoard);
      move();
    } else if (movedTile === LINE_DESTROYER_TILE_ID || targetTile === LINE_DESTROYER_TILE_ID) {
      const isVertical = startPos.x === endPos.x;
      const newBoard = handleLineDestroyerEffect(endPos, isVertical, _.cloneDeep(board));
      setBoard(newBoard);
      move();
    } else if (movedTile === BOMB_TILE_ID || targetTile === BOMB_TILE_ID) {
      const newBoard = handleBombEffect(endPos, _.cloneDeep(board));
      setBoard(newBoard);
      move();
    } else {
      const boardAfterMove = checkForAllMatches(updatedBoard, subsTilesToGo);
      setBoard(boardAfterMove);
  
      if (_.isEqual(boardAfterMove, updatedBoard)) {
        setTimeout(() => {
          setBoard(board);
        }, TILE_SPEED_MS);
      } else {
        move();
      }
    }
  
    setSelectedTile(null);
  };
  
  

  const handleTileClick = (tilePos: TilePos) => {
    // Ha nem játszható vagy immovable/hole tile, akkor ne történjen semmi
    if (
      !isPlayable ||
      board[tilePos.y][tilePos.x] === IMMOVABLE_TILE_ID ||
      board[tilePos.y][tilePos.x] === HOLE_TILE_ID
    ) {
      setSelectedTile(null);
      return;
    }
  
    // Kiválasztás logika: ha nincs kiválasztva semmi, állítsuk be a jelenlegit
    if (!selectedTile) {
      setSelectedTile(tilePos);
    } else {
      // Ha már van egy kiválasztott csempe, indítsuk el az interakciót
      handleInteraction(selectedTile, tilePos);
    }
  };
  
  

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (hasEmptyTiles(board)) {
        setBoard((prevBoard) => addNewRow(getValuesDown(prevBoard), numbers));
        setIsPlayable(true);
      } else if (!_.isEqual(board, checkForAllMatches(board))) {
        setBoard(checkForAllMatches(board, subsTilesToGo));
      }
    }, TILE_SPEED_MS);

    return () => clearTimeout(timeoutId);
  }, [board, numbers, subsTilesToGo, setBoard, setIsPlayable]);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, tilePos: TilePos) => {
    if (
      !isPlayable ||
      board[tilePos.y][tilePos.x] === IMMOVABLE_TILE_ID ||
      board[tilePos.y][tilePos.x] === HOLE_TILE_ID
    ) {
      return;
    }

    setFirstDraggedDiv(tilePos);
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>, tilePos: TilePos) => {
    if (!isPlayable || !firstDraggedDiv) {
      return;
    }

    const direction = calculateDirection(
      e.screenX - firstDraggedDiv.x,
      e.screenY - firstDraggedDiv.y
    );

    if (!direction) {
      setFirstDraggedDiv(null);
      return;
    }

    const newPosition = calculateNewPosition(tilePos, direction);
    handleInteraction(firstDraggedDiv, newPosition);
    setFirstDraggedDiv(null);
  };

  const handleLineDestroyerEffect = (tilePos: TilePos, isVertical: boolean, board: number[][]) => {
    let updatedBoard = _.cloneDeep(board);

    if (isVertical) {
      for (let i = 0; i < updatedBoard.length; i++) {
        if (updatedBoard[i][tilePos.x] === BOMB_TILE_ID) {
          updatedBoard = handleBombEffect({ x: tilePos.x, y: i }, updatedBoard);
        }
        if (
          updatedBoard[i][tilePos.x] !== IMMOVABLE_TILE_ID &&
          updatedBoard[i][tilePos.x] !== HOLE_TILE_ID
        ) {
          subsTilesToGo(updatedBoard[i][tilePos.x], 1);
          updatedBoard[i][tilePos.x] = 0;
        }
      }
    } else {
      for (let j = 0; j < updatedBoard[tilePos.y].length; j++) {
        if (updatedBoard[tilePos.y][j] === BOMB_TILE_ID) {
          updatedBoard = handleBombEffect({ x: j, y: tilePos.y }, updatedBoard);
        }
        if (
          updatedBoard[tilePos.y][j] !== IMMOVABLE_TILE_ID &&
          updatedBoard[tilePos.y][j] !== HOLE_TILE_ID
        ) {
          subsTilesToGo(updatedBoard[tilePos.y][j], 1);
          updatedBoard[tilePos.y][j] = 0;
        }
      }
    }

    return updatedBoard;
  };

  const handleBombEffect = (tilePos: TilePos, board: number[][]) => {
    const updatedBoard = _.cloneDeep(board);

    for (let i = tilePos.y - 1; i <= tilePos.y + 1; i++) {
      for (let j = tilePos.x - 1; j <= tilePos.x + 1; j++) {
        if (
          i >= 0 &&
          i < updatedBoard.length &&
          j >= 0 &&
          j < updatedBoard[i].length &&
          updatedBoard[i][j] !== IMMOVABLE_TILE_ID &&
          updatedBoard[i][j] !== HOLE_TILE_ID
        ) {
          subsTilesToGo(updatedBoard[i][j], 1);
          updatedBoard[i][j] = 0;
        }
      }
    }

    return updatedBoard;
  };

  return (
    <div>
      <S.Wrapper size={board.length}>
        {board.map((row, y) =>
          row.map((tile, x) => (
            <Tile
            // eslint-disable-next-line react/no-array-index-key
            key={`${y}-${x}`}
              id={`${y}${x}`}
              tile={tile}
              isPlayable={isPlayable}
              isSelected={selectedTile?.x === x && selectedTile?.y === y}
              onClick={() => handleTileClick({ x, y })}
              dragStart={(e) => handleDragStart(e, { x, y })}
              dragOver={(e) => e.preventDefault()}
              dragEnd={(e) => handleDragEnd(e, { x, y })}
            />
          ))
        )}
      </S.Wrapper>
    </div>
  );
};

export default Board;
