import { motion } from "framer-motion";
import styled from "styled-components";

export const Wrapper = styled(motion.div)`
  position: fixed;
  overflow: hidden;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5); /* Átlátszó háttér */
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    background: rgba(30, 30, 50, 0.8); /* Átlátszóbb, modern háttér */
    width: 35%; /* Szélesebb doboz */
    max-width: 400px; /* Maximum szélesség asztali nézetben */
    padding: 20px; /* Extra belső tér */
    border-radius: 1rem;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.7);
    align-items: center; /* Belső elemek középre igazítása */

    h2 {
      font-size: 2rem; /* Kisebb szövegméret */
      color: #3ea197; /* Sötétzöld (#3ea197 - ForestGreen) */
      margin: 0 0 20px 0; /* Alsó margó a cím alatt */
      text-transform: uppercase;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
      text-align: center;
    }

    button {
      background: linear-gradient(to bottom, #3ea197 5%, #3ea197 100%); /* Sötétzöld átmenet */
      border-radius: 8px;
      border: none;
      cursor: pointer;
      color: #ffffff;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 10px 20px;
      text-transform: uppercase;
      margin: 10px 0; /* Gombok közötti tér */
      width: 80%; /* Középre igazított széles gombok */
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      transition: all 0.3s ease-in-out;

      &:hover {
        background: linear-gradient(to bottom, #02bfbf 5%, #02bfbf 100%);
      }
      &:active {
        transform: scale(0.95);
      }
    }
  }

  @media (max-width: 768px) {
    div {
      width: 80%; /* Mobilon szélesebb doboz */
      padding: 15px; /* Kisebb belső tér */
    }

    h2 {
      font-size: 1.8rem; /* Mobilon kisebb szöveg */
    }

    button {
      font-size: 1rem;
      padding: 8px 16px;
      width: 100%; /* Mobilon teljes szélesség */
    }
  }
`;
