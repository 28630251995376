import styled from "styled-components";

export const Cell = styled.div<{ playable: boolean; isHole?: boolean; isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: 5px;
  background-color: ${({ isHole }) => (isHole ? "#1a252b" : "transparent")};
  cursor: ${({ playable }) => (playable ? "grab" : "default")};
  border-radius: 10px;
  border: ${({ isSelected }) => (isSelected ? "1px solid #FFD700" : "1px solid transparent")};

  img {
    pointer-events: none; /* Letiltja a kattinthatóságot */
    user-select: none; /* Tiltja a szöveg kijelölését */
  }

  &:active {
    cursor: grabbing; /* Húzás közben másik kurzor */
  }
`;
