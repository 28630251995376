import React from 'react'
import { Link } from 'react-router-dom'
import { Box , Flex , Button, Skeleton, Text} from '@pancakeswap/uikit'
import styled, { keyframes } from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import Page from '../PageChart'
import App from './App'

const ButtonText = styled(Text)`
  display: none;
  ${({ theme }) => theme.mediaQueries.xs} {
    display: block;
  }
`
const ImageWrapper = styled.div`
flex: none;
margin: 0 auto;
margin-right: 5px;
${({ theme }) => theme.mediaQueries.md} {
  flex: none;
  margin: 0 auto;
  margin-right: 5px;

}
`
const Partnership: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Page >  
      <App  />   
      <iframe  title="gr" width="100%"  height="800" frameBorder="0" scrolling="no" src="https://coinbrain.com/embed/bnb-0xFAaA87943bFca6D97434bE3d26C589647FEA4375?theme=custom&accent=3ad5a3&padding=16&background=000000&chart=1&trades=1&simple=0"/>
      <Skeleton width={0} height={50} />

      </Page> 
     )
    }   



export default Partnership
