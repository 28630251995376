import styled from "styled-components";

interface WrapperProps {
    size: number
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%; /* Teljes szélesség */
  height: 100%; /* Teljes magasság */
  max-width: 700px; /* Nagyobb képernyőkhöz */
  max-height: 700px;
  min-width: 250px;
  min-height: 250px;
  margin: auto; /* Automatikus középre igazítás */
  display: grid;
  background: #2b3a42;
  backdrop-filter: blur(8px);
  border: 1px solid #aaa;
  grid-template: repeat(${({ size }) => size}, 1fr) / repeat(${({ size }) => size}, 1fr);
  position: relative; /* Ne legyen abszolút, hogy rugalmasan illeszkedjen */
  box-sizing: border-box;
  gap: 4px; /* Csempék közti rés */

  @media (max-width: 1024px) {
    /* Notebook méretek */
    max-width: 600px;
    max-height: 600px;
    gap: 3px;
  }

  @media (max-width: 768px) {
    /* Tablet méretek */
    max-width: 500px;
    max-height: 500px;
    gap: 2px;
  }

@media (max-width: 480px) {
  /* Kis képernyők, mobil */
  width: 100%;
  height: auto; /* Hagyjuk, hogy a tartalom határozza meg a magasságot */
  max-height: none; /* Engedjük, hogy nagyobb legyen, mint a képernyő */
  gap: 1px;
  margin: 0 auto 100px auto; /* Oldalsó középre igazítás megtartva, alsó margóval */
}
`;
