import _ from "lodash";
import { IMMOVABLE_TILE_ID } from "./ImmovableTile.utils";
import { HOLE_TILE_ID } from "./hole.utils";

// Speciális csempe ID-k
export const LINE_DESTROYER_TILE_ID = -3;
export const BOMB_TILE_ID = -4;

// Ellenőrző függvények
export const isLineDestroyerTile = (tile: number): boolean => tile === LINE_DESTROYER_TILE_ID;
export const isBombTile = (tile: number): boolean => tile === BOMB_TILE_ID;

// Line Destroyer generálása
export const generateLineDestroyerTiles = (
  board: number[][],
  count: number
): number[][] => {
  const newBoard = _.cloneDeep(board);
  const boardSize = newBoard.length * newBoard[0].length;

  // Ellenőrizzük, hogy ne próbáljunk több Line Destroyert hozzáadni, mint a tábla mérete
  const tilesToPlace = Math.min(count, boardSize);
  let tilesPlaced = 0;

  while (tilesPlaced < tilesToPlace) {
    const row = Math.floor(Math.random() * newBoard.length);
    const col = Math.floor(Math.random() * newBoard[0].length);

    // Csak akkor helyezünk le Line Destroyert, ha a mező üres és nem törhetetlen, mozdíthatatlan vagy bomba
    if (
      newBoard[row][col] !== LINE_DESTROYER_TILE_ID &&
      newBoard[row][col] !== BOMB_TILE_ID &&
      newBoard[row][col] !== IMMOVABLE_TILE_ID &&
      newBoard[row][col] !== HOLE_TILE_ID &&
      newBoard[row][col] !== -1 // UNBREAKABLE_TILE_ID
    ) {
      newBoard[row][col] = LINE_DESTROYER_TILE_ID;
      tilesPlaced++;
    }
  }
  return newBoard;
};

// Bombák generálása
export const generateInitialBombs = (
  board: number[][],
  count: number
): number[][] => {
  const newBoard = _.cloneDeep(board);
  const boardSize = newBoard.length * newBoard[0].length;

  const tilesToPlace = Math.min(count, boardSize);
  let tilesPlaced = 0;

  while (tilesPlaced < tilesToPlace) {
    const row = Math.floor(Math.random() * newBoard.length);
    const col = Math.floor(Math.random() * newBoard[0].length);

    if (
      newBoard[row][col] !== BOMB_TILE_ID &&
      newBoard[row][col] !== IMMOVABLE_TILE_ID &&
      newBoard[row][col] !== HOLE_TILE_ID &&
      newBoard[row][col] !== -1 // UNBREAKABLE_TILE_ID
    ) {
      newBoard[row][col] = BOMB_TILE_ID;
      tilesPlaced++;
    }
  }
  return newBoard;
};
