import React, { FC } from "react";
import * as S from "./Tile.style";
import { candies } from "../../assets";
import gold from "../../assets/candes/gold.png";
import immovableImage from "../../assets/candes/immovable.png"; 
import lineDestroyerImage from "../../assets/candes/line.png";
import bombImage from "../../assets/candes/bomb.png";
import hole from "../../assets/candes/hole.png"; 
import { UNBREAKABLE_TILE_ID } from "../Board/UnbreakableTile.utils";
import { IMMOVABLE_TILE_ID, isImmovableTile } from "../Board/ImmovableTile.utils";
import { LINE_DESTROYER_TILE_ID, BOMB_TILE_ID } from "../Board/SpecialTiles.utils";
import { HOLE_TILE_ID } from "../Board/hole.utils";

interface TileProps {
  dragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  dragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  dragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
  tile: number;
  id: string;
  isPlayable: boolean;
  isSelected: boolean; // Új tulajdonság a kijelöléshez
  onClick: () => void; // Kattintás eseménykezelő
}

const Tile: FC<TileProps> = ({
  dragStart,
  dragOver,
  dragEnd,
  tile,
  id,
  isPlayable,
  isSelected, 
  onClick,
}) => {
  const isDraggable = isPlayable && !isImmovableTile(tile);

  return (
    <S.Cell
      draggable={isDraggable}
      onDragStart={dragStart}
      onDragOver={dragOver}
      onDragEnd={dragEnd}
      onClick={onClick}
      id={id}
      playable={isPlayable}
      isHole={tile === HOLE_TILE_ID} 
      isSelected={isSelected}
    >
      {tile === IMMOVABLE_TILE_ID ? (
        <img width="50" src={immovableImage} alt="Immovable Tile" draggable={false} />
      ) : tile === UNBREAKABLE_TILE_ID ? (
        <img width="50" src={gold} alt="Unbreakable Tile" draggable={false} />
      ) : tile === LINE_DESTROYER_TILE_ID ? (
        <img width="50" src={lineDestroyerImage} alt="Line Destroyer Tile" draggable={false} />
      ) : tile === BOMB_TILE_ID ? (
        <img width="50" src={bombImage} alt="Bomb Tile" draggable={false} />
      ) : tile === HOLE_TILE_ID ? (
        <img width="50" src={hole} alt="Hole Tile" draggable={false} />
      ) : tile ? (
        <img width="50" src={candies[tile]} alt="Candy Tile" draggable={false} />
      ) : null}
    </S.Cell>
  );
};

export default Tile;
