import _ from "lodash";

// Konstans a lyukakhoz
export const HOLE_TILE_ID = -5 as number;

/**
 * Generál egy meghatározott számú lyukat (HOLE_TILE_ID) a táblán.
 * A lyukak rögzített üres helyek lesznek, amelyeket a játék nem érint.
 *
 * @param board - A játék tábla
 * @param count - A létrehozandó lyukak száma
 * @returns A módosított tábla lyukakkal
 */
export const generateHoles = (board: number[][], count: number): number[][] => {
  const newBoard = _.cloneDeep(board);
  const boardSize = newBoard.length * newBoard[0].length;
  const holesToPlace = Math.min(count, boardSize);
  let holesPlaced = 0;

  while (holesPlaced < holesToPlace) {
    const row = Math.floor(Math.random() * newBoard.length);
    const col = Math.floor(Math.random() * newBoard[0].length);

    // Csak akkor helyezünk el lyukat, ha az adott pozícióban még nincs lyuk
    if (newBoard[row][col] !== HOLE_TILE_ID) {
      newBoard[row][col] = HOLE_TILE_ID;
      holesPlaced++;
    }
  }
  return newBoard;
};

/**
 * Ellenőrzi, hogy a megadott csempe lyuk-e.
 *
 * @param tile - A vizsgált csempe
 * @returns True, ha a csempe lyuk, különben false
 */
export const isHoleTile = (tile: number): boolean => {
  return tile === HOLE_TILE_ID;
};
