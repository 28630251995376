import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly; /* Egyenletes elosztás */
  align-items: center; /* Függőleges középre igazítás */
  background: linear-gradient(145deg, #121212, #1e1e2e);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 15px 20px; /* Több függőleges belső tér */
  margin-bottom: 10px;

  p {
    margin: 0;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    display: flex;
    align-items: center; /* Szöveg és kép függőleges középre igazítása */

    .lover {
      text-transform: lowercase;
      margin-left: -5px;
    }

    img {
      transform: translateY(0); /* Kép igazítása alaphelyzetbe */
      margin-right: 5px;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap; /* Mobilon az adatok egymás alá kerülhetnek */
    p {
      font-size: 1rem;
    }
  }
`;
